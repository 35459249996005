import Vue from "vue";
import router from "@/router";
import { hasWalkInContent } from "@/api/auth";
import { isWebcheckinEnabled } from "@/api/util";
import { getSettings } from "@/api/settings";
import { getSession } from "@/api/key";

export default {
  authenticate({ commit, state, dispatch }, params = {}) {
    return new Promise(async (resolve) => {
      const _this = this;
      let demoRoom = undefined;
      let demoName = undefined;

      if (this._vm.$cookies.get("lang-key")) {
        this._vm.$config.language_key = this._vm.$cookies.get("lang-key");
      }
      const mode = state.mode || router.currentRoute.query.mode;
      commit("SET_IS_WALKIN", mode === "walkin" || mode === "walkin-only");
      if (state.isWalkin) {
        // params must be empty for the interface to work
        params = {};
        params.walkin = true;
      } else if (
        mode === "demo" &&
        ((router.currentRoute.query.name && router.currentRoute.query.room) ||
          (params.name && params.room))
      ) {
        demoRoom = "demo";
        demoName = "demo";
      }

      commit("SET_STATUS", "fetching");
      commit("SET_LOADING", true, { root: true });
      params.room = params.room ? params.room : undefined;
      params.name = params.name ? params.name : undefined;
      params.checkin = params.checkin ? params.checkin : undefined;
      params.userId = params.userId ? params.userId : undefined;
      this._vm.$onesocket.send({
        function: "authenticate",
        module: "SQMOD_Auth",
        data: {
          ...{
            ...(params.walkin !== true
              ? {
                  ...{
                    room: router.currentRoute.query.room,
                    name: router.currentRoute.query.name,
                  },
                }
              : {}),
          },
          ...params,
          ...{ ...(state.mode === "demo" ? { name: demoName } : {}) },
          ...{ ...(state.mode === "demo" ? { room: demoRoom } : {}) },
        },
        onMessage(data) {
          commit("SET_LOADING", false, { root: true });
          if (data.payload.status === "OK") {
            commit("SET_SKIN");
            commit("SET_MODE", mode);
            const authResult = {
              ...data.payload.root,
              ...{
                checkin: data.payload.root.checkin
                  ? [
                      {
                        ...{
                          room: params.room,
                          name: params.name,
                          residents: data.payload.root.checkin,
                        },
                        ...data.payload.root.checkin[0],
                        ...(mode === "demo"
                          ? {
                              room: params.room,
                              name: params.name,
                              residents: data.payload.root.checkin,
                            }
                          : {}),
                      },
                    ]
                  : null,
              },
            };

            commit("SET_AUTH_RESULT", authResult);

            if (
              !state.isWalkin &&
              mode !== "demo" &&
              authResult.checkin &&
              authResult.checkin[0] &&
              authResult.checkin[0].residents &&
              authResult.checkin[0].residents.length > 1
            ) {
              commit("SET_STATUS", "multiple_residents");

              // when logged in via IP in CMS
              const { name, room, userId } = router.currentRoute.query;
              if ((!name && !room) || (!userId && state.isWalkin)) {
                router.push({
                  name: "login",
                  query: { theme: state.themeId, property: state.propertyId },
                });
              }
            } else {
              if (
                authResult.checkin &&
                authResult.checkin[0] &&
                authResult.checkin[0].id
              ) {
                const { name, userId } = router.currentRoute.query;
                if (!name || (!userId && state.isWalkin)) {
                  router.push({
                    name: "login",
                    query: { theme: state.themeId, property: state.propertyId },
                  });
                }

                if (
                  authResult.checkin[0].permanent === 1 &&
                  authResult.checkin[0].residents &&
                  authResult.checkin[0].residents.length > 0
                ) {
                  const residentsNotPermanent = authResult.checkin[0].residents.filter(
                    (resident) => {
                      return resident.permanent !== 1;
                    }
                  );
                  if (residentsNotPermanent.length > 0) {
                    let checkin0 = JSON.parse(
                      JSON.stringify(state.authResult.checkin[0])
                    );

                    commit("SET_AUTH_RESULT", {
                      ...authResult,
                      checkin: [{ ...checkin0, ...residentsNotPermanent[0] }],
                    });
                  }
                }

                params.userId = state.authResult.checkin[0].id;
              }
              if (state.isWalkin) {
                dispatch("setLoginData", params);
              }
              commit("SET_CHECKIN_DATA", state.authResult.checkin);

              if (
                (state.authResult.checkin &&
                  state.authResult.checkin.length > 0 &&
                  params.userId) ||
                params.walkin
              ) {
                commit("SET_STATUS", "registered");
              } else {
                commit("SET_STATUS", "not_registered");
              }
            }

            resolve();

            if (state.status !== "multiple_residents") {
              dispatch("loadSkinJson");
              dispatch("roomControl/getRoomControl", {}, { root: true });
            }
          } else {
            commit("SET_LOADING", false, { root: true });

            _this._vm.$logger.info(
              "session-store",
              "authenticate failed, not_registered"
            );
            commit("SET_STATUS", "not_registered");
            resolve();
          }
        },
        onError(error) {
          commit("SET_LOADING", false, {
            root: true,
          });
          commit("SET_STATUS", "not_registered");
          resolve();
          _this._vm.$logger.error(
            "session-store",
            "Failed to fetch authenticate",
            error
          );
        },
      });
    });
  },
  async logout({ commit, dispatch, state }) {
    const {
      theme = state.themeId,
      property = state.propertyId,
    } = router.currentRoute.query;
    this._vm.$ga.event({
      eventCategory: "Logout",
      eventAction: "clicked",
      eventLabel: `clicked`,
      eventValue: 1,
    });
    await dispatch("language/deleteCookies", null, { root: true });
    await dispatch("deleteLoginData");
    commit("RESET");
    window.location = router.resolve({
      query: { autologin: undefined, theme, property },
    }).href;
    window.location.reload();
  },
  setCookies({}, { mode, room, name, checkin, userId, location, skin }) {
    router.app.$cookies.set("mode", mode, 1);
    router.app.$cookies.set("room", room, 1);
    router.app.$cookies.set("name", name, 1);
    router.app.$cookies.set("checkin", checkin, 1);
    router.app.$cookies.set("userId", userId, 1);
    router.app.$cookies.set("location", location, 1);
    router.app.$cookies.set("skin", skin, 1);
  },
  loadSkinJson({ state, commit }) {
    return new Promise((resolve, reject) => {
      const skinJsonPath = `${this._vm.$config.managerSkins}${
        state.authResult.skin.project_id
      }/${state.skinId}${state.isWalkin ? "/oneapp" : ""}/menu_${
        (state.isWalkin ? "walkin_" : "") +
        this._vm.$config.language_key.toUpperCase()
      }.json?ts=${new Date().getTime()}`;

      commit("SET_LOADING", true, {
        root: true,
      });

      Vue.http
        .get(skinJsonPath)
        .then((response) => {
          commit("SET_SKIN_DATA", response.body);
          resolve();
        })
        .catch((response) => {
          commit("SET_LOADING", false, { root: true });
          Vue.logger.error(
            "skin-store",
            "Failed to getSkinData, failed to fetch",
            skinJsonPath,
            response.message
          );
          resolve();
        });
    });
  },
  async getSettings(
    { commit, rootState },
    { property = router.currentRoute.query.property }
  ) {
    if (
      rootState.session.settingsStatus !== "success" &&
      rootState.session.settingsStatus !== "fetching"
    ) {
      commit("SET_LOADING", true, { root: true });
      commit("REQUEST_SETTINGS");
      try {
        commit("SUCCESS_SETTINGS", await getSettings({ propertyId: property }));
      } catch (e) {
        commit("FAILURE_SETTINGS");
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    }
  },
  async initTheme(
    { state, commit, dispatch },
    {
      theme = router.currentRoute.query.theme,
      property = router.currentRoute.query.property,
    }
  ) {
    if (
      theme &&
      property &&
      theme != state.themeId &&
      property != state.propertyId
    ) {
      commit("SET_LOADING", true, { root: true });
      commit("SET_THEME_ID", theme);
      commit("SET_PROPERTY_ID", property);
      dispatch("isWebcheckinEnabled", { propertyId: property });
      try {
        await dispatch(
          "theme/getTheme",
          { themeId: state.themeId },
          { root: true }
        );
      } catch (e) {
        console.error("Failed to init theme", e.message);
      } finally {
        commit("SET_LOADING", false, { root: true });
      }
    }
  },
  init({ commit, dispatch }) {
    return new Promise(async (resolve) => {
      try {
        await this._vm.$config.init();
        commit("SUCCESS_INIT_CONFIG");
        commit("SET_CURRENCY_CONFIG", this._vm.$config);
      } catch (e) {
        this._vm.$logger.error("session, init config, ", e.message);
      }

      try {
        await this._vm.$onesocket.init();
        commit("SUCCESS_INIT_ONESOCKET");
      } catch (e) {
        this._vm.$logger.error("session, init onesocket, ", e.message);
      }

      commit("SET_LOADING", false, { root: true });
      commit("SET_LOGIN_DATA", {
        room: window.localStorage.getItem("login-room"),
        name: window.localStorage.getItem("login-name"),
        checkin: window.localStorage.getItem("login-checkin"),
        userId: window.localStorage.getItem("login-userId"),
      });
      if (
        router.currentRoute.query.name &&
        router.currentRoute.query.room &&
        router.currentRoute.query.autologin == "1"
      ) {
        commit("SET_LOGIN_DATA", {
          name: router.currentRoute.query.name,
          room: router.currentRoute.query.room,
          checkin: router.currentRoute.query.checkin,
          userId: router.currentRoute.query.userId,
        });
      }

      await dispatch("language/getLanguageList", {}, { root: true });
      resolve();
    });
  },
  initLogger({ commit, dispatch }) {
    return new Promise(async (resolve) => {
      await this._vm.$logger.init();
      resolve();
      OneappWebcheckinEnabledResource;
    });
  },
  setMode({ commit }, { mode = router.currentRoute.query.mode }) {
    commit("SET_MODE", mode || undefined);
  },
  reAuthenticate({ dispatch, state }) {
    dispatch("authenticate", {
      room: state.loginData.room,
      name: state.loginData.name,
      checkin: state.loginData.checkin,
      userId: state.loginData.userId,
    });
  },
  async authenticateById({ commit, state, dispatch }, checkin = {}) {
    const userId = checkin.userId || checkin.id;

    const authResult = {
      ...state.authResult,
      ...{
        checkin: checkin
          ? [
              {
                ...checkin,
                ...{
                  residents: [state.authResult.checkin],
                  room: checkin.room,
                  name: checkin.name,
                  last_name: checkin.last_name,
                },
              },
            ]
          : null,
      },
    };

    router.push({
      query: {
        userId,
        room: checkin.room,
        name: checkin.last_name || checkin.name,
        checkin: checkin.checkin,
      },
    });

    dispatch("setLoginData", {
      room: checkin.room,
      name: checkin.last_name || checkin.name,
      checkin: checkin.checkin,
      userId,
    });
    commit("SET_STATUS", "registered");
    commit("SET_AUTH_RESULT", authResult);
    dispatch("loadSkinJson");
    dispatch("roomControl/getRoomControl", {}, { root: true });
  },
  async setLoginData(
    { commit, dispatch },
    {
      room = undefined,
      name = undefined,
      checkin = undefined,
      userId = undefined,
      id = undefined,
    }
  ) {
    if (!room || !name) {
      dispatch("deleteLoginData");
    } else {
      if (room) {
        window.localStorage.setItem("login-room", room);
      } else {
        window.localStorage.removeItem("login-room");
      }
      if (name) {
        window.localStorage.setItem("login-name", name);
      } else {
        window.localStorage.removeItem("login-name");
      }
      if (checkin) {
        window.localStorage.setItem("login-checkin", checkin);
      } else {
        window.localStorage.removeItem("login-checkin");
      }
      if (userId || id) {
        window.localStorage.setItem("login-userId", userId || id);
      } else {
        window.localStorage.removeItem("login-userId");
      }
      commit("SET_LOGIN_DATA", {
        room,
        name,
        checkin,
        userId: userId || id,
      });
    }
  },
  deleteLoginData({ commit }) {
    window.localStorage.removeItem("login-room");
    window.localStorage.removeItem("login-name");
    window.localStorage.removeItem("login-checkin");
    window.localStorage.removeItem("login-userId");
    commit("SET_LOGIN_DATA", {
      room: undefined,
      name: undefined,
      checkin: undefined,
      userId: undefined,
    });
  },
  async hasWalkInContent({ commit, state }, skinId = state.skinId) {
    try {
      commit("SET_WALKIN_CONTENT", await hasWalkInContent({ skinId }));
    } catch (e) {
      commit("SET_WALKIN_CONTENT", false);
    }
  },
  async isWebcheckinEnabled(
    { commit, state },
    { propertyId = state.propertyId }
  ) {
    try {
      commit("REQUEST_IS_WEBCHECKIN_ENABLED");
      commit(
        "SUCCESS_IS_WEBCHECKIN_ENABLED",
        await isWebcheckinEnabled({ propertyId })
      );
    } catch (e) {
      commit("FAILURE_IS_WEBCHECKIN_ENABLED");
    }
  },
  goToWebcheckin({ state }) {
    const name = state.name || router.currentRoute.query.name || "";
    window.location = `http://app.cloud.at-visions.org/webcheckin/login?property=${state.propertyId}&theme=${state.themeId}&name=${name}`;
  },
  goToWebcheckout({ dispatch, rootState, state }) {
    const {
      name = router.currentRoute.query.name,
      mode = router.currentRoute.query.mode,
    } = rootState;
    if (mode === "demo" || mode === "demo-use-pms") {
      dispatch("demoCheckout", null, { root: true });
    } else {
      let checkoutLink = rootState.session.settings.webcheckin.webcheckinLink
        ? rootState.session.settings.webcheckin.webcheckinLink
        : "https://app.cloud.at-visions.org/webcheckin/login?";
      window.location = `${checkoutLink}?property=${state.propertyId}&theme=${state.themeId}&name=${name}`;
    }
  },
  goToDDS() {
    window.location = "https://w-o-w.app/#/";
  },
  initMagicButton({ commit }) {
    let interval = setInterval(() => {
      commit("INIT_MAGIC_BUTTON");
    }, 7000);
    commit("SET_MAGIC_BUTTON_INTERVAL", interval);
  },
  async getONEkeySession(
    { commit, state },
    {
      propertyId = router.currentRoute.query.property,
      room = state.loginData.room || "atv-demo",
      vendor,
      device,
      expireAt = "2020-10-20T16:00:00.000Z",
    }
  ) {
    commit("FETCH_ONEKEY_SESSION");
    try {
      commit(
        "SUCCESS_ONEKEY_SESSION",
        await getSession({ propertyId, room, vendor, device, expireAt })
      );
    } catch (e) {
      commit("FAILURE_ONEKEY_SESSION");
    }
  },
};
